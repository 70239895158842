<template>
  <span>
    {{ mainData }}
    <span v-if="moreDataCount > 0">
      <div
        v-if="!isExpanded"
        @click="expand"
        class="underline font-semibold text-blue-dark-2 cursor-pointer inline-block"
      >
        {{ `+ ${moreDataCount} more` }}
      </div>
      <div
        v-if="isExpanded && showHideButton"
        @click="collapse"
        class="underline font-semibold text-blue-dark-2 cursor-pointer inline-block"
      >
        {{ `hide` }}
      </div>
  </span>
  </span>
</template>
<script>
export default {
  props: {
    data: { type: Array },
    showHideButton: { type: Boolean }
  },
  data: () => ({
    isExpanded: false,
  }),
  computed: {
    mainData() {
      return this.isExpanded
        ? this.data.join(", ")
        : this.data.slice(0, 2).join(", ");
    },
    moreDataCount() {
      return this.data.length - 2;
    },
  },
  methods: {
    expand() {
      this.isExpanded = true;
    },
    collapse() {
      this.isExpanded = false;
    },
  },
};
</script>

<template>
  <div>
    <div class="flex flex-row w-full py-2 pl-3 items-center">
      <div class="flex w-55 justify-center mr-1 pr-3">
        <p class="font-normal text-md text-grey-dark-2">
          {{ index + 1 }}
        </p>
      </div>
      <div class="flex flex-grow max-w-1/4 mr-1">
        <p class="flex flex-grow text-md text-grey-dark-1 font-semibold">
          {{ source.name }}
        </p>
      </div>
      <div class="flex flex-grow max-w-1/4">
        <p class="text-md text-grey-dark-2">
          <ExpandMore :data="jobTitles" :showHideButton="true" />
        </p>
      </div>
      <div class="flex flex-grow flex-col max-w-1/5 ml-auto">
        <p class="text-md text-grey-dark-2" v-for="survey in surveys">
          {{ survey }}
        </p>
      </div>
      <div class="flex flex-grow max-w-1/10 justify-center">
        <p class="text-md text-grey-dark-2" :class="source.isActive ? 'text-green' : 'text-red'">
          {{ !!source.isActive ? "Active" : "Inactive" }}
        </p>
      </div>
      <div class="flex flex-grow max-w-1/10 justify-center">
        <div class="flex gap-1">
          <IconButton :icon="view" :onClick="() => viewJobRole()" class="iconButton border border-grey-border" />
          <IconButton :icon="edit" :onClick="() => editJobRole()" class="iconButton border border-grey-border" />
        </div>
      </div>
    </div>
    <div class="border border-grey-light-1 border-top-none" />
  </div>
</template>
<script>
import ExpandMore from "../../../../components/ExpandMore/ExpandMore.vue";
import IconButton from "../../../../components/IconButton/IconButton.vue";
import edit from "@/assets/img/icons/edit.svg";
import view from "@/assets/img/icons/view.svg";
import { mapActions } from "vuex";
import { getLatestVersion, getLatestPublishedVersion } from '../Common/globalEditUtils';
import {
  displayGoToDraft,
  displayCreateNewVersion,
  displayGoToVersionItBelongs
} from '../Common/globalEditUtils';

export default {
  name: "ListItem",
  components: { IconButton, ExpandMore },
  data: () => ({ edit, view }),
  props: {
    index: { type: Number },
    source: { type: Object, default: () => ({}) },
  },
  computed: {
    jobTitles() {
      return this.source.jobTitles.split(",");
    },
    surveys() {
      return this.source.attachedSurveyNames;
    },
  },
  methods: {
    ...mapActions({
      setJobRoleManagementMode: "companies/setJobRoleManagementMode",
      getProductById: "companies/getProductById",
      openModal: "modals/openModal",
      showError: 'alerts/showError'
    }),
    async viewJobRole() {
      await this.setJobRoleManagementMode({
        mode: "edit",
        selectedJobRoleId: this.source.id,
      });
      this.$router.push("/job-roles/management/general-information");
    },
    async editJobRole() {
      var product = await this.getProductById({ id: this.source.productId });

      let latestVersion = getLatestVersion(product.productVersions);
      let latestPublishedVersion = getLatestPublishedVersion(product.productVersions, product.draftVersion);

      if (product.draftVersion !== null && product.draftVersion !== latestVersion) {
        this.showError({
          messageHeader: 'Invalid job role',
          message:
            'Something went wrong. Select another job role or contact the administrator.',
        });
      } else {
        this.openModal({
          modal: "editJobRoleOrCompetencyModal",
          props: {
            isJobRole: true,
            productId: product.id,
            productName: product.name,
            displayGoToDraft: displayGoToDraft(latestVersion, product.draftVersion),
            displayGoToVersionItBelongs: displayGoToVersionItBelongs(product.currentVersion, product.draftVersion),
            displayCreateNewVersion: displayCreateNewVersion(product.draftVersion),
            latestVersion: latestVersion,
            latestPublishedVersion: latestPublishedVersion,
            currentVersion: product.currentVersion,
            draftVersion: product.draftVersion
          },
        });
      }
    },
  },
};
</script>

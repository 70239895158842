export const getLatestVersion = (productVersions) => {
    if (!productVersions) productVersions = ["1.0"];
    return productVersions[productVersions.length - 1];
};

export const getLatestPublishedVersion = (productVersions, latestDraftVersion) => {
    if (!productVersions) productVersions = ["1.0"];
    let latestPublishedVersion = productVersions[productVersions.length - 1];
    if (latestDraftVersion !== null && productVersions.length > 1)
        latestPublishedVersion = productVersions[productVersions.length - 2];
    return latestPublishedVersion;
};

export const displayGoToDraft = (latestVersion, draftVersion) => {
    if (draftVersion === latestVersion) return true;
    return false;
};

export const displayGoToVersionItBelongs = (currentVersion, draftVersion) => {
    if (draftVersion !== null && draftVersion === currentVersion) return false;
    return true;
};

export const displayCreateNewVersion = (draftVersion) => {
    if (draftVersion === null) return true;
    return false;
};
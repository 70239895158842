<template>
  <div>
    <p
      class="text-xl text-grey-light font-medium font-headers mb-3 font-bold text-grey-dark-3"
    >
      Job Roles
    </p>
    <JobRolesTable class="mb-5" />
  </div>
</template>

<script>
import JobRolesTable from "../components/JobRolesTable";
export default {
  name: "JobRoleManagement",
  components: { JobRolesTable },
};
</script>
